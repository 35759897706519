import { useContext, useEffect } from "react";
import { FieldValues, FormProvider, useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";

import { PlantGuest } from "../../../../../api/types/plants";
import { useApiValidation } from "../../../../../hooks/useApiValidation";
import { useGuestUpdate } from "../../../api/useGuestUpdate";
import { CafsContext } from "../../../CafsContext";
import { CafsSection } from "../../../const/cafs-section";
import { mapInitialValues } from "../../../utils/map-initial-values";
import { renderVariableRow } from "../../../utils/render-variable-row";
import { CafsTable } from "../../cafs-table/CafsTable";
import { SectionRemarks } from "../SectionRemarks";

import { guestEditSchema } from "./guest-edit-schema";
import { useGuestVariables } from "./useGuestVariables";

export type PlantGuestEditProps = {
  guest: PlantGuest;
  guestId: number | undefined;
  readonly?: boolean;
};

export function GuestEdit({ guest, guestId }: PlantGuestEditProps) {
  const variables = useGuestVariables();
  const { isReadMode } = useContext(CafsContext);

  const methods = useForm<FieldValues>({
    shouldFocusError: true,
    mode: "onChange",
    resolver: yupResolver(guestEditSchema),
  });

  const { updateGuest, apiErrors } = useGuestUpdate(+guestId!);

  useEffect(() => {
    methods.reset(mapInitialValues(guest, variables));
  }, [guest]);

  const disabled =
    !methods.formState.isValid || isReadMode || !methods.formState.isDirty;

  useApiValidation(apiErrors, methods);

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(data => updateGuest(data))}>
        <SectionRemarks<PlantGuest> name="GeneralRemarks" data={guest} />
        <CafsTable<PlantGuest>
          sectionName={CafsSection.PlantProduct}
          variables={variables}
          render={renderVariableRow}
          values={guest ?? {}}
          guestName={guest.Name?.Current ?? guest.Name?.Base}
          disabled={disabled}
        />
      </form>
    </FormProvider>
  );
}
