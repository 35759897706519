import { PlantLabour } from "../../../../../api/types/plants";
import { CafsVariablesCollection } from "../../../../../types/cafs-variable-collection";
import { CafsVariableType } from "../../../const/cafs-variable-type";

export function useLaboursVariables(): CafsVariablesCollection<PlantLabour> {
  return [
    {
      name: "LabourRemarks",
      type: CafsVariableType.Remarks,
    },
    {
      name: "LabourEstDayPHa",
      type: CafsVariableType.Input,
    },
    {
      name: "LabourEstFemale",
      type: CafsVariableType.Input,
    },
    {
      name: "LabourMaintDayPHaPY",
      type: CafsVariableType.Input,
    },
    {
      name: "LabourMaintFemale",
      type: CafsVariableType.Input,
    },
  ];
}
