import { useEffect } from "react";
import { FieldValues, FormProvider, useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import { Alert } from "@mui/material";

import { PlantBiodiversity } from "../../../../../api/types/plants";
import { useApiValidation } from "../../../../../hooks/useApiValidation";
import { useFetchData } from "../../../../../hooks/useFetchData";
import { SectionProps } from "../../../../../types/section-props";
import { useBiodiversityData } from "../../../api/useBiodiversityData";
import { useBiodiversityUpdate } from "../../../api/useBiodiversityUpdate";
import { CafsSection } from "../../../const/cafs-section";
import { useSectionSaveDisabled } from "../../../hooks/useSectionSaveDisabled";
import { mapInitialValues } from "../../../utils/map-initial-values";
import { mapSubmitValues } from "../../../utils/map-submit-values";
import { renderVariableRow } from "../../../utils/render-variable-row";
import { CafsTable } from "../../cafs-table/CafsTable";
import { SectionRemarks } from "../SectionRemarks";

import { biodiversitySchema } from "./biodiversity-schema";
import { useBiodiversityVariables } from "./useBiodiveristyVariables";

export function BiodiversitySection({ expanded }: SectionProps) {
  const variables = useBiodiversityVariables();

  const { shouldFetchData } = useFetchData(expanded);
  const { biodiversityData, isPending } = useBiodiversityData(shouldFetchData);
  const { updateBiodiversity, apiErrors } = useBiodiversityUpdate({
    onSuccess: () => {},
  });

  const methods = useForm({
    resolver: yupResolver(biodiversitySchema),
    mode: "onChange",
  });

  const { disabled } = useSectionSaveDisabled(methods, isPending);

  const handleSubmit = (data: FieldValues) => {
    updateBiodiversity(mapSubmitValues(data));
  };

  useEffect(() => {
    if (biodiversityData) {
      methods.reset(mapInitialValues(biodiversityData, variables));
    }
  }, [biodiversityData]);

  useApiValidation(apiErrors, methods);

  return biodiversityData && !isPending ? (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handleSubmit)}>
        <SectionRemarks<PlantBiodiversity>
          name="BiodiversityRemarks"
          data={biodiversityData}
        />
        <CafsTable<PlantBiodiversity>
          sectionName={CafsSection.PlantBiodiversity}
          variables={variables}
          render={renderVariableRow}
          values={biodiversityData ?? {}}
          disabled={disabled}
        />
      </form>
    </FormProvider>
  ) : (
    <div className="w-full">
      <Alert severity="info">Loading data</Alert>
    </div>
  );
}
