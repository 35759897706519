import React, { useContext } from "react";
import { FieldValues, FormState } from "react-hook-form";
import { FormattedMessage } from "react-intl";

import { RemoveCircle } from "@mui/icons-material";
import { Alert, Button } from "@mui/material";

import { Cafs } from "../../../api/types/cafs";
import { Check } from "../../../components/icons/Check";
import { Access } from "../../../consts/access";
import { Status } from "../../../consts/status";
import { useModal } from "../../../hooks/useModal";
import { CafsContext } from "../CafsContext";
import { ApproveDraftModal } from "../modals/ApproveDraftModal";

import { CafsStatus as StatusCmp } from "./CafsStatus";
import { CafsTitle } from "./CafsTitle";
import { ChildrenInfo } from "./ChildrenInfo";
import { RejectDraftModal } from "./RejectDraftModal";
import { TierInfo } from "./TierInfo";
import { UpdateInfo } from "./UpdateInfo";

export type PlantTopBarProps = {
  data: Cafs;
  formState: FormState<FieldValues>;
};

export function CafsInfo({ data, formState }: PlantTopBarProps) {
  const { isReadMode, isDraftNew } = useContext(CafsContext);

  const renderButtons =
    data?.Status !== Status.Approved && data.AccessType === Access.Full;

  const disabled =
    formState.isDirty ||
    (!data.EnglishName?.Current?.length && !data.EnglishName?.Base?.length);

  const {
    open: rejectOpened,
    handleOpen: handleRejectOpen,
    handleClose: handleRejectClose,
  } = useModal();

  const {
    open: approveOpened,
    handleOpen: handleApproveOpen,
    handleClose: handleApproveClose,
  } = useModal();

  return (
    <div className="flex flex-col mb-4">
      <div className="flex flex-row justify-between items-end mb-6 pb-6 border-b border-gray-100 w-full">
        <CafsTitle data={data} />

        {renderButtons ? (
          <div className="flex flex-row gap-4 items-end justify-end">
            <Button
              color="primary"
              variant="contained"
              disabled={disabled}
              data-testid="approve_draft_btn"
              startIcon={<Check />}
              onClick={handleApproveOpen}>
              <FormattedMessage
                id="CAFS_DETAILS.APPROVE_DRAFT_BTN"
                defaultMessage="Approve draft"
                description="Draft approve btn on cafs details"
              />
            </Button>

            <Button
              color="error"
              variant="outlined"
              startIcon={<RemoveCircle />}
              disabled={disabled}
              data-testid="reject_draft_btn"
              onClick={handleRejectOpen}>
              <FormattedMessage
                id="CAFS_DETAILS.APPROVE_DRAFT_BTN"
                defaultMessage="Reject draft"
                description="Draft reject btn on cafs details"
              />
            </Button>
          </div>
        ) : null}
      </div>

      <div className="flex flex-row gap-12 mb-8">
        <StatusCmp status={data.Status} />
        <UpdateInfo date={data.UpdatedAt} />
        <TierInfo data={data} />
        <ChildrenInfo data={data} />
      </div>

      <div>
        {isReadMode && (
          <div className="mb-4 mt-6">
            <Alert severity="info" data-testid="readmode-alert">
              <FormattedMessage
                id="INFO.READONLY_MODE"
                defaultMessage="You are working in readonly mode."
                description="Readonly mode messafe in CAFS info"
              />
            </Alert>
          </div>
        )}
        {!data.EnglishName?.Current?.length && (
          <div className="mb-6" data-testid="alert-warning">
            <Alert severity="warning" data-testid="empty-englishName-alert">
              {isDraftNew ? (
                <FormattedMessage
                  id="ALERT.NEEDS_ACTION_DRAFT_NEW"
                  defaultMessage={`<b>{fieldName}</b> field needs to be filled and saved before any further actions.`}
                  description="Action needed alert message in draft new status"
                  values={{
                    fieldName: <b>EnglishName</b>,
                    b: chunks => <b>{chunks}</b>,
                  }}
                />
              ) : (
                <FormattedMessage
                  id="ALERT.NEEDS_ACTION_DRAFT_EDIT"
                  defaultMessage={`<b>{fieldName}</b> field needs to be filled, saved and <b>approved</b> before any further actions.`}
                  description="Action needed alert message in draft edit status"
                  values={{
                    fieldName: <b>EnglishName</b>,
                    b: chunks => <b>{chunks}</b>,
                  }}
                />
              )}
            </Alert>
          </div>
        )}
        {formState.isDirty && !!data?.EnglishName?.Current?.length && (
          <div className="mb-2">
            <Alert severity="warning">There are unsaved changes.</Alert>
          </div>
        )}
      </div>

      <RejectDraftModal
        open={rejectOpened}
        onClose={handleRejectClose}
        cafs={data}
      />

      <ApproveDraftModal open={approveOpened} onClose={handleApproveClose} />
    </div>
  );
}
