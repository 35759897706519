import { useContext } from "react";

import { PlantProduct } from "../../../../../api/types/plants";
import { CafsVariablesCollection } from "../../../../../types/cafs-variable-collection";
import { CafsVariableType } from "../../../const/cafs-variable-type";
import { useCategoriesOptions } from "../../../hooks/useCategoriesOptions";
import { usePlantPartsOptions } from "../../../hooks/usePlantPartsOptions";
import { useProductCodesOptions } from "../../../hooks/useProductCodesOptions";
import { useProductsOptions } from "../../../hooks/useProductsOptions";
import { ProductsContext } from "../../../ProductsContext";
import { renderForCrop } from "../../../utils/render-for-crop";

export function useProductCreateVariables(): CafsVariablesCollection<PlantProduct> {
  const { plantProducts, allProducts } = useContext(ProductsContext);
  const { options: categories } = useCategoriesOptions(plantProducts);
  const { options: products } = useProductsOptions(allProducts);
  const { options: plantParts } = usePlantPartsOptions(plantProducts);

  const { options: availableCodes } = useProductCodesOptions(plantProducts);

  return [
    {
      type: CafsVariableType.Select,
      name: "Category",
      options: categories,
      required: true,
    },
    {
      type: CafsVariableType.Select,
      name: "PlantPart",
      options: plantParts,
      required: true,
    },
    {
      type: CafsVariableType.Select,
      name: "Code",
      options: availableCodes,
      required: true,
    },
    {
      type: CafsVariableType.Select,
      name: "ProductId",
      options: products,
      required: true,
    },
    {
      type: CafsVariableType.Input,
      name: "DMContentFraction",
    },
    {
      type: CafsVariableType.Input,
      name: "DegradationAnnualFraction",
    },
    {
      type: CafsVariableType.Input,
      name: "HarvestYears",
      text: true,
    },
    {
      type: CafsVariableType.Input,
      name: "HarvestMonths",
      text: true,
    },
    {
      type: CafsVariableType.Input,
      name: "HarvestAgeMonths",
      text: true,
      renderCondition: renderForCrop,
    },
    {
      type: CafsVariableType.Input,
      name: "HarvestFractions",
      text: true,
    },
    {
      type: CafsVariableType.Input,
      name: "BurnAtHarvestFraction",
    },
    {
      type: CafsVariableType.Input,
      name: "RemoveAtHarvestFraction",
    },
    {
      type: CafsVariableType.Input,
      name: "DecompPYearFraction",
    },
    {
      type: CafsVariableType.Input,
      name: "ProductFreshName",
      text: true,
    },
    {
      type: CafsVariableType.Input,
      name: "ProductDryName",
      text: true,
    },
    {
      type: CafsVariableType.Input,
      name: "Y1stProd",
    },
    {
      type: CafsVariableType.Boolean,
      name: "PriceValidForDryProduct",
    },
    {
      type: CafsVariableType.Input,
      name: "YToOptProd",
    },
    {
      type: CafsVariableType.Input,
      name: "YWithOptProd",
    },
    {
      type: CafsVariableType.Input,
      name: "ProdEndCycleFraction",
    },
    {
      type: CafsVariableType.Info,
      name: "MaxFreshProductionAnnual",
    },
    {
      type: CafsVariableType.Input,
      name: "HarvestKgFreshPHour",
    },
    {
      type: CafsVariableType.Input,
      name: "NkgPTDM",
    },
    {
      type: CafsVariableType.Input,
      name: "PkgPTDM",
    },
    {
      type: CafsVariableType.Input,
      name: "KkgPTDM",
    },
    {
      type: CafsVariableType.Boolean,
      name: "IsBelowGroundBiomass",
    },
  ];
}
