import { useEffect } from "react";
import { FieldValues, FormProvider, useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import { Alert } from "@mui/material";

import { InputDefinition } from "../../../../../api/types/inputs";
import { useApiValidation } from "../../../../../hooks/useApiValidation";
import { useFetchData } from "../../../../../hooks/useFetchData";
import { SectionProps } from "../../../../../types/section-props";
import { useInputDefinitionData } from "../../../api/useInputDefinitionData";
import { useInputDefinitionUpdate } from "../../../api/useInputDefinitionUpdate";
import { CafsSection } from "../../../const/cafs-section";
import { useSectionSaveDisabled } from "../../../hooks/useSectionSaveDisabled";
import { mapInitialValues } from "../../../utils/map-initial-values";
import { mapSubmitValues } from "../../../utils/map-submit-values";
import { renderVariableRow } from "../../../utils/render-variable-row";
import { CafsTable } from "../../cafs-table/CafsTable";
import { SectionRemarks } from "../SectionRemarks";

import { definitionValidationSchema } from "./input-definition-schema";
import { useInputDefinitionVariables } from "./useInputDefinitionVariables";

export function InputDefinitionSection({ expanded }: SectionProps) {
  const { shouldFetchData } = useFetchData(expanded);
  const { data, isPending } = useInputDefinitionData(shouldFetchData);

  const { updateDefinition, apiErrors } = useInputDefinitionUpdate({
    onSuccess: () => {},
  });

  const handleDefinitionUpdate = (data: FieldValues) => {
    updateDefinition(mapSubmitValues(data));
  };

  const methods = useForm<FieldValues>({
    resolver: yupResolver(definitionValidationSchema),
    mode: "onChange",
  });

  const variables = useInputDefinitionVariables(methods);

  useEffect(() => {
    if (data) {
      methods.reset(mapInitialValues(data, variables));
    }
  }, [data]);

  useApiValidation(apiErrors, methods);

  const { disabled } = useSectionSaveDisabled(methods, isPending);

  return data && !isPending ? (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handleDefinitionUpdate)}>
        <SectionRemarks<InputDefinition> name="DefinitionRemarks" data={data} />
        <CafsTable<InputDefinition>
          sectionName={CafsSection.InputDefinition}
          variables={variables}
          render={renderVariableRow}
          values={data ?? {}}
          disabled={disabled}
        />
      </form>
    </FormProvider>
  ) : (
    <div className="w-full">
      <Alert severity="info">Loading data</Alert>
    </div>
  );
}
