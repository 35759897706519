import { useEffect } from "react";
import { FieldValues, FormProvider, useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import { Alert } from "@mui/material";

import { PlantGrowthConditions } from "../../../../../api/types/plants";
import { useApiValidation } from "../../../../../hooks/useApiValidation";
import { useFetchData } from "../../../../../hooks/useFetchData";
import { SectionProps } from "../../../../../types/section-props";
import { useGrowthConditionsData } from "../../../api/useGrowthConditionsData";
import { useGrowthConditionsUpdate } from "../../../api/useGrowthConditionsUpdate";
import { CafsSection } from "../../../const/cafs-section";
import { useSectionSaveDisabled } from "../../../hooks/useSectionSaveDisabled";
import { mapInitialValues } from "../../../utils/map-initial-values";
import { mapSubmitValues } from "../../../utils/map-submit-values";
import { renderVariableRow } from "../../../utils/render-variable-row";
import { CafsTable } from "../../cafs-table/CafsTable";
import { SectionRemarks } from "../SectionRemarks";

import { growthConditionsSchema } from "./growth-conditions-schema";
import { useGrowthConditionsVariables } from "./useGrowthConditionsVariables";

export function GrowthConditionsSection({ expanded }: SectionProps) {
  const variables = useGrowthConditionsVariables();

  const { shouldFetchData } = useFetchData(expanded);
  const { growthConditionsData, isPending } =
    useGrowthConditionsData(shouldFetchData);
  const { updateGrowthConditions, apiErrors } = useGrowthConditionsUpdate({
    onSuccess: () => {},
  });

  const methods = useForm<FieldValues>({
    resolver: yupResolver(growthConditionsSchema),
    mode: "onChange",
  });

  useEffect(() => {
    if (growthConditionsData) {
      methods.reset(mapInitialValues(growthConditionsData, variables));
    }
  }, [growthConditionsData]);

  const handleSubmit = (data: FieldValues) => {
    updateGrowthConditions(mapSubmitValues(data));
  };

  useApiValidation(apiErrors, methods);

  const { disabled } = useSectionSaveDisabled(methods, isPending);

  return growthConditionsData && !isPending ? (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handleSubmit)}>
        <SectionRemarks<PlantGrowthConditions>
          name="GrowthConditionsRemarks"
          data={growthConditionsData}
        />
        <CafsTable<PlantGrowthConditions>
          sectionName={CafsSection.PlantGrowthCondition}
          variables={variables}
          render={renderVariableRow}
          values={growthConditionsData ?? {}}
          disabled={disabled}
        />
      </form>
    </FormProvider>
  ) : (
    <div className="w-full">
      <Alert severity="info">Loading data</Alert>
    </div>
  );
}
