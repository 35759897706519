import * as React from "react";
import { UseFormReturn } from "react-hook-form";

import { PlantType } from "../../../api/types/plants";
import { RHFAutocompleteField } from "../../../components/rhf/RhfAutocomplete";
import { RhfFormField } from "../../../components/rhf/RhfFormField";
import { RhfInput } from "../../../components/rhf/RhfInput";
import { RhfYesNoRadioGroup } from "../../../components/rhf/RhfYesNoRadioGroup";
import { CafsVariable } from "../../../types/cafs-variable";
import { CafsVariableType } from "../const/cafs-variable-type";

export function renderBaseRow<T>(
  variable: CafsVariable<keyof T>,
  plantType: PlantType | undefined,
  methods: UseFormReturn,
) {
  if (
    variable.renderCondition &&
    methods &&
    !variable.renderCondition({ methods: methods, cafsType: plantType })
  )
    return <></>;

  switch (variable.type) {
    case CafsVariableType.Input:
      return (
        <div key={variable.name as string} className="mb-6">
          <RhfFormField name={variable.name as string}>
            <RhfInput
              name={`${variable.name as string}.Base`}
              type={variable.text ? "text" : "number"}
              placeholder={variable.name as string}
              label={variable.name as string}
              required={variable.required}
            />
          </RhfFormField>
        </div>
      );
    case CafsVariableType.Select:
      return (
        <div
          data-testid={variable.name as string}
          key={variable.name as string}
          className="mb-6">
          <RhfFormField name={variable.name as string}>
            <RHFAutocompleteField
              control={methods.control}
              name={`${variable.name as string}.Base`}
              options={variable.options ?? []}
              placeholder={variable.name as string}
            />
          </RhfFormField>
        </div>
      );
    case CafsVariableType.Boolean:
      return (
        <div key={variable.name as string} className="mb-6">
          <div className="text-sm mb-2">{variable.name as string}</div>
          <div>
            <RhfFormField name={variable.name as string}>
              <RhfYesNoRadioGroup
                name={`${variable.name as string}.Base`}
                defaultValue={undefined}
                dataTestId={`${variable.name as string}.base`}
              />
            </RhfFormField>
          </div>
        </div>
      );
    case CafsVariableType.Text:
      return (
        <div key={variable.name as string} className="mb-6">
          <RhfFormField name={variable.name as string}>
            <div className="text-sm">
              <RhfInput
                placeholder={variable.name as string}
                multiline
                rows={3}
                label={variable.name as string}
                name={`${variable.name as string}.Base`}
              />
            </div>
          </RhfFormField>
        </div>
      );
    default:
      return null;
  }
}
