import { useEffect } from "react";
import { FieldValues, FormProvider, useForm } from "react-hook-form";
import { useIntl } from "react-intl";

import { yupResolver } from "@hookform/resolvers/yup";

import { Reference } from "../../api/types/references";
import { RhfFormField } from "../../components/rhf/RhfFormField";
import { RhfInput } from "../../components/rhf/RhfInput";
import { useApiValidation } from "../../hooks/useApiValidation";
import { ApiValidationErrors } from "../../types/api-error";
import { SaveBtn } from "../cafs-view/components/cafs-table/SaveBtn";

import { referenceSchema } from "./reference-schema";

export type ReferencesFormProps = {
  data?: Reference | undefined | null;
  onSubmit: (reference: FieldValues) => void;
  apiErrors?: ApiValidationErrors;
};

export function ReferencesForm({
  data,
  onSubmit,
  apiErrors,
}: ReferencesFormProps) {
  const methods = useForm<FieldValues>({
    resolver: yupResolver(referenceSchema),
    mode: "onChange",
  });
  const { formatMessage } = useIntl();

  useEffect(() => {
    if (data) {
      methods.reset(data);
    }
  }, [data]);

  useApiValidation(apiErrors, methods);

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <div className="mb-4">
          <RhfFormField name="Author">
            <RhfInput
              name="Author"
              placeholder={formatMessage({
                id: "FORM.REFERENCE.AUTHOR_PLACEHOLDER",
                defaultMessage: "Author*",
                description: "Author placeholder on references form",
              })}
            />
          </RhfFormField>
        </div>

        <div className="mb-4">
          <RhfFormField name="Title">
            <RhfInput
              name="Title"
              placeholder={formatMessage({
                id: "FORM.REFERENCE.TITLE_PLACEHOLDER",
                defaultMessage: "Title*",
                description: "Title placeholder on references form",
              })}
            />
          </RhfFormField>
        </div>

        <div className="mb-4">
          <RhfFormField name="Year">
            <RhfInput
              name="Year*"
              placeholder={formatMessage({
                id: "FORM.REFERENCE.YEAR_PLACEHOLDER",
                defaultMessage: "Year",
                description: "Year placeholder on references form",
              })}
            />
          </RhfFormField>
        </div>

        <div className="mb-4">
          <RhfFormField name="Subtitle">
            <RhfInput
              name="Subtitle"
              placeholder={formatMessage({
                id: "FORM.REFERENCE.SUBTITLE_PLACEHOLDER",
                defaultMessage: "Subtitle",
                description: "Subtitle placeholder on references form",
              })}
            />
          </RhfFormField>
        </div>

        <div className="mb-4">
          <RhfFormField name="Edition">
            <RhfInput
              name="Edition"
              placeholder={formatMessage({
                id: "FORM.REFERENCE.EDITION_PLACEHOLDER",
                defaultMessage: "Edition",
                description: "Edition placeholder on references form",
              })}
            />
          </RhfFormField>
        </div>

        <div className="mb-4">
          <RhfFormField name="DOI">
            <RhfInput
              name="DOI"
              placeholder={formatMessage({
                id: "FORM.REFERENCE.DOI_PLACEHOLDER",
                defaultMessage: "DOI",
                description: "DOI placeholder on references form",
              })}
            />
          </RhfFormField>
        </div>

        <div className="mb-4">
          <RhfFormField name="Link">
            <RhfInput
              name="Link"
              placeholder={formatMessage({
                id: "FORM.REFERENCE.LINK_PLACEHOLDER",
                defaultMessage: "Link",
                description: "Link placeholder on references form",
              })}
            />
          </RhfFormField>
        </div>
        <div className="mt-8">
          <SaveBtn disabled={!methods.formState.isValid} />
        </div>
      </form>
    </FormProvider>
  );
}
