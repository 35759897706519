import { useEffect } from "react";
import { FieldValues, FormProvider, useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import { Alert } from "@mui/material";

import { InputCost } from "../../../../../api/types/inputs";
import { useApiValidation } from "../../../../../hooks/useApiValidation";
import { useFetchData } from "../../../../../hooks/useFetchData";
import { SectionProps } from "../../../../../types/section-props";
import { useInputCostData } from "../../../api/useInputCostData";
import { useInputCostUpdate } from "../../../api/useInputCostUpdate";
import { CafsSection } from "../../../const/cafs-section";
import { useSectionSaveDisabled } from "../../../hooks/useSectionSaveDisabled";
import { mapInitialValues } from "../../../utils/map-initial-values";
import { mapSubmitValues } from "../../../utils/map-submit-values";
import { renderVariableRow } from "../../../utils/render-variable-row";
import { CafsTable } from "../../cafs-table/CafsTable";
import { SectionRemarks } from "../SectionRemarks";

import { inputCostSchema } from "./input-cost-schema";
import { useInputCostVariables } from "./useInputCostVariables";

export function InputCosts({ expanded }: SectionProps) {
  const { shouldFetchData } = useFetchData(expanded);
  const { costsData, isPending } = useInputCostData(shouldFetchData);

  const { updateCost, apiErrors } = useInputCostUpdate({
    onSuccess: () => {},
  });

  const handleCostUpdate = (data: FieldValues) => {
    updateCost(mapSubmitValues(data));
  };

  const methods = useForm<FieldValues>({
    resolver: yupResolver(inputCostSchema),
    mode: "onChange",
  });

  const variables = useInputCostVariables(methods);

  useEffect(() => {
    if (costsData) {
      methods.reset(mapInitialValues(costsData, variables));
    }
  }, [costsData]);

  const { disabled } = useSectionSaveDisabled(methods, isPending);

  useApiValidation(apiErrors, methods);

  return costsData && !isPending ? (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handleCostUpdate)}>
        <SectionRemarks<InputCost> name="CostsRemarks" data={costsData} />
        <CafsTable<InputCost>
          sectionName={CafsSection.InputCost}
          variables={variables}
          render={renderVariableRow}
          values={costsData ?? {}}
          disabled={disabled}
        />
      </form>
    </FormProvider>
  ) : (
    <div className="w-full">
      <Alert severity="info">Loading data</Alert>
    </div>
  );
}
