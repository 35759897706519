import { useEffect } from "react";
import { UseFormReturn } from "react-hook-form";

import { InputCost } from "../../../../../api/types/inputs";
import { CafsVariablesCollection } from "../../../../../types/cafs-variable-collection";
import { SelectOption } from "../../../../../types/select-option";
import { useFetchProducts } from "../../../../products-view/api/useFetchProducts";
import { CafsVariableType } from "../../../const/cafs-variable-type";

export function useInputCostVariables(
  methods: UseFormReturn,
): CafsVariablesCollection<InputCost> {
  const { products } = useFetchProducts();
  const category = methods.watch("Type.Base");

  useEffect(() => {
    if (
      (category && category !== "IrrigationSprinkler") ||
      category !== "IrrigationDrip"
    ) {
      methods.setValue("IrrigationCostsPerMMPerHa.Base", null);
    }

    if (
      (category && category === "IrrigationSprinkler") ||
      category === "IrrigationDrip"
    ) {
      methods.resetField("IrrigationCostsPerMMPerHa.Base");
    }
  }, [category]);

  return [
    {
      name: "CostsRemarks",
      type: CafsVariableType.Remarks,
    },
    {
      name: "ProductId",
      type: CafsVariableType.Select,
      options: products?.Products?.map(th => {
        return { label: th.Name, id: th.Id } as SelectOption;
      }),
    },
    {
      name: "CostInitialUnitPerHa",
      type: CafsVariableType.Input,
    },
    {
      name: "InitialCostPerUnit",
      type: CafsVariableType.Info,
    },
    {
      name: "InitialCostTotalHa",
      type: CafsVariableType.Input,
    },
    {
      name: "RunningCostPHaPY",
      type: CafsVariableType.Input,
    },
    {
      name: "IrrigationCostsPerMMPerHa",
      type: CafsVariableType.Input,
    },
    {
      name: "CostsStartYear",
      type: CafsVariableType.Input,
    },
    {
      name: "CostsEndYear",
      type: CafsVariableType.Input,
    },
    {
      name: "InitialCostTotalIsInvestment",
      type: CafsVariableType.Boolean,
    },
  ];
}
