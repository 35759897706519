import {
  CafsVariableCollection,
  NumberCafsValue,
} from "../../../api/types/cafs";
import { DataFormat } from "../../../consts/data-format";
import { CafsFormValue } from "../../../types/cafs-form-value";
import { CafsVariablesCollection } from "../../../types/cafs-variable-collection";
import { isNullOrUndefined } from "../../../utils/is-null-or-undefined";
import { CafsVariableType } from "../const/cafs-variable-type";

export function mapInitialValues<T extends CafsVariableCollection<T>>(
  data: T | undefined,
  variables: CafsVariablesCollection<T>,
) {
  return variables.reduce((acc, variable) => {
    const value = data![variable.name] as NumberCafsValue;
    let newVariableValue: unknown = null;

    switch (variable.type) {
      case CafsVariableType.Input:
        newVariableValue = mapInputVariable(value, variable.format);
        break;
      case CafsVariableType.Select:
        newVariableValue = mapSelectVariable(value);
        break;
      case CafsVariableType.Boolean:
        newVariableValue = mapBooleanVariable(value);
        break;
      case CafsVariableType.Remarks:
        newVariableValue = mapRemarksVariable(value);
        break;
      case CafsVariableType.Calculated:
        newVariableValue = mapCalculatedVariable(value);
        break;
      default:
        newVariableValue = {
          ...value,
          Base: isNullOrUndefined(value?.Base) ? "" : String(value?.Base),
        };
    }
    return { ...acc, [variable.name]: newVariableValue };
  }, {} as CafsVariableCollection<T>);
}

function mapInputVariable(
  value: NumberCafsValue,
  format?: DataFormat,
): CafsFormValue {
  switch (format) {
    case DataFormat.Hour:
      return {
        ...value,
        Base: isNullOrUndefined(value?.Base) ? null : String(value?.Base),
        [DataFormat.Hour]: false,
      };
    case DataFormat.Year:
      return {
        ...value,
        Base: isNullOrUndefined(value?.Base) ? null : String(value?.Base),
      };
    default:
      return {
        ...value,
        Base: isNullOrUndefined(value?.Base) ? "" : String(value?.Base),
      };
  }
}

function mapSelectVariable(value: NumberCafsValue): CafsFormValue {
  return {
    ...value,
    Base: isNullOrUndefined(value?.Base) ? null : value?.Base,
  };
}

function mapBooleanVariable(value: NumberCafsValue): CafsFormValue {
  return {
    ...value,
  };
}

function mapRemarksVariable(value: NumberCafsValue): CafsFormValue {
  return {
    ...value,
    Base: value?.Base ?? value.Current,
  };
}

function mapCalculatedVariable(value: NumberCafsValue): CafsFormValue {
  return {
    Calculated: value.Calculated,
    Current: value.Current,
    Parent: value.Parent,
  };
}
