import { useContext } from "react";
import { useFormContext } from "react-hook-form";

import { Table, TableBody, TableContainer } from "@mui/material";

import { CafsVariableCollection } from "../../../../api/types/cafs";
import { CafsVariablesCollection } from "../../../../types/cafs-variable-collection";
import { useTooltips } from "../../api/useTooltips";
import { CafsContext } from "../../CafsContext";
import { CafsSection } from "../../const/cafs-section";
import { RenderTableRowFn } from "../../types/render-table-row-fn";

import { CafsTableHeader } from "./CafsTableHeader";
import { SaveBtn } from "./SaveBtn";

type CafsTableProps<SectionName> = {
  sectionName: CafsSection;
  variables: CafsVariablesCollection<SectionName>;
  values: CafsVariableCollection<SectionName> | undefined;
  render: RenderTableRowFn<SectionName>;
  disabled: boolean;
  productCode?: string;
  guestName?: string | null;
};

export function CafsTable<SectionName>({
  variables,
  render,
  values,
  sectionName,
  disabled,
  productCode,
  guestName,
}: CafsTableProps<SectionName>) {
  const methods = useFormContext();
  const { cafs, isReadMode } = useContext(CafsContext);
  const { tooltips } = useTooltips(values, sectionName);

  return (
    <div>
      <TableContainer>
        <Table data-testid={`${sectionName}_table`}>
          <CafsTableHeader />
          <TableBody>
            {(variables || []).map(variable =>
              render(
                variable,
                values,
                sectionName,
                tooltips,
                cafs?.Type,
                isReadMode,
                productCode,
                guestName,
                methods,
              ),
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <div className="mt-8">
        <SaveBtn data-testid="save_section_btn" disabled={disabled} />
      </div>
    </div>
  );
}
