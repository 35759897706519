import { useEffect } from "react";
import { FieldValues, FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

import { yupResolver } from "@hookform/resolvers/yup";
import { Alert } from "@mui/material";

import { PlantWoodDensity } from "../../../../../api/types/plants";
import { useApiValidation } from "../../../../../hooks/useApiValidation";
import { useFetchData } from "../../../../../hooks/useFetchData";
import { SectionProps } from "../../../../../types/section-props";
import { useWoodDensityData } from "../../../api/useWoodDensityData";
import { useWoodDensityUpdate } from "../../../api/useWoodDensityUpdate";
import { CafsSection } from "../../../const/cafs-section";
import { useSectionSaveDisabled } from "../../../hooks/useSectionSaveDisabled";
import { mapInitialValues } from "../../../utils/map-initial-values";
import { mapSubmitValues } from "../../../utils/map-submit-values";
import { renderVariableRow } from "../../../utils/render-variable-row";
import { CafsTable } from "../../cafs-table/CafsTable";
import { SectionRemarks } from "../SectionRemarks";

import { useWoodDensityVariables } from "./useWoodDensityVariables";
import { wooddensitySchema } from "./wooddensity-schema";

export function WoodDensitySection({ expanded }: SectionProps) {
  const variables = useWoodDensityVariables();

  const { datasetId, id } = useParams();
  const { shouldFetchData } = useFetchData(expanded);

  const { woodDensityData, isPending } = useWoodDensityData(
    datasetId!,
    id!,
    shouldFetchData,
  );

  const { updateWoodDensity, apiErrors } = useWoodDensityUpdate({
    onSuccess: () => {},
  });

  const methods = useForm<FieldValues>({
    resolver: yupResolver(wooddensitySchema),
    mode: "onChange",
  });

  const { disabled } = useSectionSaveDisabled(methods, isPending);

  useEffect(() => {
    if (woodDensityData) {
      methods.reset(mapInitialValues(woodDensityData, variables));
    }
  }, [woodDensityData]);

  useApiValidation(apiErrors, methods);

  const handleSubmit = (data: FieldValues) => {
    updateWoodDensity(mapSubmitValues(data));
  };

  return woodDensityData && !isPending ? (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handleSubmit)}>
        <SectionRemarks<PlantWoodDensity>
          name="WoodDensityRemarks"
          data={woodDensityData}
        />
        <CafsTable<PlantWoodDensity>
          sectionName={CafsSection.PlantWoodDensity}
          variables={variables}
          render={renderVariableRow}
          values={woodDensityData ?? {}}
          disabled={disabled}
        />
      </form>
    </FormProvider>
  ) : (
    <div className="w-full">
      <Alert severity="info">Loading data</Alert>
    </div>
  );
}
