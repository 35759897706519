import { Accordion, AccordionProps } from "@mui/material";
import { styled } from "@mui/system";
import colors from "tailwindcss/colors";

type StyledAccordionProps = {
  expanded: boolean;
  highlighted?: boolean;
} & AccordionProps;

export const StyledAccordion = styled((props: StyledAccordionProps) => (
  <Accordion {...props} />
))(({ theme, expanded, highlighted }) => ({
  borderTop: expanded ? `3px solid ${theme.palette.primary.main}` : "none",
  fontWeight: expanded ? "bold" : "inherit",
  boxShadow: "none",
  "&:not(.Mui-expanded)": {
    backgroundColor: highlighted ? colors.blue[50] : "inherit",
  },
  "&:not(.Mui-expanded):hover": {
    backgroundColor: highlighted
      ? colors.blue[50]
      : theme.palette.action?.hover,
  },
}));
