import { PlantRoots } from "../../../../../api/types/plants";
import { CafsVariablesCollection } from "../../../../../types/cafs-variable-collection";
import { SelectOption } from "../../../../../types/select-option";
import { useRootTypes } from "../../../api/useRootTypes";
import { CafsVariableType } from "../../../const/cafs-variable-type";

import { rootLayerDistributionFn } from "./functions/rootLayerDistributionFn";

export function useRootsVariables(): CafsVariablesCollection<PlantRoots> {
  const { rootTypes } = useRootTypes();
  return [
    {
      name: "RootsRemarks",
      type: CafsVariableType.Remarks,
    },
    {
      name: "RootDepthM",
      type: CafsVariableType.Input,
    },
    {
      name: "RootNPKUptakeEffectivityFactor",
      type: CafsVariableType.Input,
    },
    {
      name: "RootTypeId",
      type: CafsVariableType.Select,
      options: rootTypes?.map(rt => {
        return { label: rt.Name, id: rt.Id } as SelectOption;
      }),
    },
    {
      name: "RootLayerDistribution",
      type: CafsVariableType.Calculated,
      calculationFn: rootLayerDistributionFn,
      dependOn: ["RootTypeId"],
      data: rootTypes,
    },
  ];
}
