import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

import { yupResolver } from "@hookform/resolvers/yup";

import { GuestResponseToPressure } from "../../../../../api/types/guests";
import { components } from "../../../../../api-client/api-schema";
import { useApiValidation } from "../../../../../hooks/useApiValidation";
import { ApiError } from "../../../../../types/api-error";
import { CafsForm } from "../../../../../types/cafs-form";
import { useGuestPressureUpdate } from "../../../api/useGuestPressureUpdate";
import { CafsSection } from "../../../const/cafs-section";
import { mapInitialValues } from "../../../utils/map-initial-values";
import { renderVariableRow } from "../../../utils/render-variable-row";
import { CafsTable } from "../../cafs-table/CafsTable";
import { SectionRemarks } from "../SectionRemarks";

import { guestPressureEditSchema } from "./guest-pressure-edit-schema";
import { useGuestPressureVariables } from "./useGuestPressureVariables";

export type GuestResponseToPressureProps = {
  data: components["schemas"]["GuestResponseToPressureModelDto"] | null;
  guestId: number | undefined;
  pressureId: number | undefined;
  categories: { id: string; label: string }[];
  customErrors?: ApiError["Body"]["Errors"];
  onCancel: () => void;
  readonly?: boolean;
};

export function GuestPressuresEdit({
  data,
  categories,
  customErrors,
  guestId,
  pressureId,
}: GuestResponseToPressureProps) {
  const variables = useGuestPressureVariables({ categories: categories });
  const { datasetId, id } = useParams();
  const methods = useForm({
    resolver: yupResolver(guestPressureEditSchema),
  });

  const { updateRtp } = useGuestPressureUpdate();

  function handleUpdateRtp(data: Partial<CafsForm<GuestResponseToPressure>>) {
    updateRtp({
      datasetId: +datasetId!,
      plantId: +id!,
      guestId: guestId!,
      guestResponseToPressureId: +pressureId!,
      data,
    });
  }

  const disabled = !methods.formState.isValid || !methods.formState.isDirty;

  useEffect(() => {
    if (data) {
      methods.reset(mapInitialValues(data, variables));
    }
  }, [data]);

  useApiValidation(customErrors, methods);

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handleUpdateRtp)}>
        <SectionRemarks<GuestResponseToPressure>
          name="Remarks"
          data={data as GuestResponseToPressure}
        />
        <CafsTable
          sectionName={CafsSection.PlantResponseToPressure}
          variables={variables}
          render={renderVariableRow}
          values={data ?? {}}
          disabled={disabled}
        />
      </form>
    </FormProvider>
  );
}
