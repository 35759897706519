import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";

import { CafsVariableCollection } from "../../../../../api/types/cafs";
import { PlantResponseToPressure } from "../../../../../api/types/plants";
import { useApiValidation } from "../../../../../hooks/useApiValidation";
import { ApiValidationErrors } from "../../../../../types/api-error";
import { usePlantPressureData } from "../../../api/usePlantPressureData";
import { usePlantPressureUpdate } from "../../../api/usePlantPressureUpdate";
import { CafsSection } from "../../../const/cafs-section";
import { useSectionSaveDisabled } from "../../../hooks/useSectionSaveDisabled";
import { mapInitialValues } from "../../../utils/map-initial-values";
import { mapSubmitValues } from "../../../utils/map-submit-values";
import { renderVariableRow } from "../../../utils/render-variable-row";
import { CafsTable } from "../../cafs-table/CafsTable";
import { SectionRemarks } from "../SectionRemarks";

import { usePressureVariables } from "./usePressureVariables";
import { validationSchema } from "./validationSchema";

export type PlantResponseToPressureProps = {
  data: PlantResponseToPressure;
  customErrors?: ApiValidationErrors;
  isReadMode?: boolean;
};

export function PressureEdit({
  data,
  customErrors,
  isReadMode,
}: PlantResponseToPressureProps) {
  const methods = useForm({ resolver: yupResolver(validationSchema) });

  const { plantPressureData } = usePlantPressureData(true);

  const variables = usePressureVariables({
    categories: plantPressureData?.Categories,
    targets: plantPressureData?.Targets,
  });

  const { updateRtp, isPending } = usePlantPressureUpdate({
    onSuccess: () => {},
  });

  const { disabled } = useSectionSaveDisabled(methods, isPending);

  useEffect(() => {
    if (data) {
      methods.reset(mapInitialValues(data, variables));
    }
  }, [data]);

  useApiValidation(customErrors, methods);

  const handleSubmit = (
    rtp: CafsVariableCollection<PlantResponseToPressure>,
  ) => {
    updateRtp({
      data: mapSubmitValues(rtp),
      plantResponseToPressureId: +data?.Id!,
    });
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handleSubmit)}>
        <SectionRemarks<PlantResponseToPressure> name="Remarks" data={data} />
        <CafsTable
          sectionName={CafsSection.PlantResponseToPressure}
          variables={variables}
          render={renderVariableRow}
          values={data ?? {}}
          disabled={disabled}
        />
      </form>
    </FormProvider>
  );
}
