import { PlantConstraint } from "../../../../../api/types/plants";
import { CafsVariablesCollection } from "../../../../../types/cafs-variable-collection";
import { CafsVariableType } from "../../../const/cafs-variable-type";

export function useConstrainstVariables(): CafsVariablesCollection<PlantConstraint> {
  return [
    {
      name: "ConstraintsRemarks",
      type: CafsVariableType.Remarks,
    },
    {
      name: "TolToDrought",
      type: CafsVariableType.Input,
    },
    {
      name: "TolToPoorSoil",
      type: CafsVariableType.Input,
    },
    {
      name: "TolToPoorPH",
      type: CafsVariableType.Input,
    },
    {
      name: "TolToPoorMgmt",
      type: CafsVariableType.Input,
    },
    {
      name: "TolToPests",
      type: CafsVariableType.Input,
    },
    {
      name: "TolToSalinity",
      type: CafsVariableType.Input,
    },
    {
      name: "TolToDiseases",
      type: CafsVariableType.Input,
    },
    {
      name: "TolToHerbicide",
      type: CafsVariableType.Input,
    },
    {
      name: "TolToPoorPollination",
      type: CafsVariableType.Input,
    },
    {
      name: "TolToPoorTemp",
      type: CafsVariableType.Input,
    },
    {
      name: "MinSuitabilityToDamage",
      type: CafsVariableType.Input,
    },
  ];
}
